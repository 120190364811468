import React, { useEffect, useState } from "react";
import MyButton from "../button/MyButton";
import { useDispatch, useSelector } from "react-redux";
import {
  createManufacturer,
  deleteManufacturer,
  getAllManufacturer,
  updateManufacturer,
} from "../../http/manufacturerApi";
import Modal from "../../components/modal/Modal";
import YON from "../../components/yon/Yon";

const Manufacturer = () => {
  const manufacturer = useSelector((state) => state.manufacturerList.list);
  console.log("manufacturer", manufacturer);

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    country: "",
    description: "",
  });
  const [isVisibleInput, setIsVisibleInput] = useState(false);
  const [editManufacturer, setEditManufacturer] = useState("");
  const [modalActive, setModalActive] = useState(false); // Управление модальным окном
  const [delId, setDelId] = useState(null); // ID для подтверждения удаления

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("name", formData.name);
    data.append("country", formData.country);
    data.append("description", formData.description);

    try {
      await dispatch(createManufacturer(data));
      setFormData({
        name: "",
        description: "",
        country: "",
      });
    } catch (error) {
      console.error("Ошибка при создании типа детали:", error);
    }
  };

  const handleEdit = (manufacturer) => {
    setEditManufacturer(manufacturer);
    setIsVisibleInput(true);
  };

  const handleSave = () => {
    dispatch(
      updateManufacturer({
        id: editManufacturer.id,
        country: editManufacturer.country,
        description: editManufacturer.description,
        name: editManufacturer.name,
      })
    );
    setIsVisibleInput(false);
  };

  const handleDeleteClick = (id) => {
    setDelId(id);
    setModalActive(true); // Открыть модальное окно
  };

  const deleteClick = async (id) => {
    try {
      dispatch(deleteManufacturer(id));
      setDelId(null);
    } catch (error) {
      console.error("Ошибка при удалении бренда детали:", error);
    }
  };

  useEffect(() => {
    dispatch(getAllManufacturer());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <h1>Работа с брендами производителя</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Название</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Страна</label>
          <input
            type="text"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Описание:</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
        </div>
        <MyButton type="submit">Создать</MyButton>
      </form>
      <div>
        {manufacturer &&
          manufacturer.map((manufacturer) => (
            <div key={manufacturer.id}>
              {editManufacturer.id === manufacturer.id && isVisibleInput ? (
                <>
                  <div>
                    {" "}
                    <label>Название:</label>
                    <input
                      defaultValue={editManufacturer.name}
                      autoFocus
                      onChange={(e) =>
                        setEditManufacturer((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <div>
                      <label>Страна:</label>
                      <input
                        defaultValue={editManufacturer.country}
                        autoFocus
                        onChange={(e) =>
                          setEditManufacturer((prev) => ({
                            ...prev,
                            country: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div>
                      {" "}
                      <label>Описание:</label>
                      <textarea
                        defaultValue={editManufacturer.description}
                        autoFocus
                        onChange={(e) =>
                          setEditManufacturer((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  <MyButton onClick={() => handleSave()} color="green">
                    Сохранить
                  </MyButton>

                  <MyButton
                    onClick={() => {
                      setEditManufacturer("");
                      setIsVisibleInput(false);
                    }}
                  >
                    Отмена
                  </MyButton>
                  <MyButton
                    onClick={() => handleDeleteClick(manufacturer.id)}
                    color="red"
                  >
                    Удалить
                  </MyButton>
                </>
              ) : (
                <>
                  <h3>{manufacturer.name}</h3>{" "}
                  <h5>страна: {manufacturer.country}</h5>{" "}
                  {!isVisibleInput && (
                    <MyButton onClick={() => handleEdit(manufacturer)}>
                      Редактировать
                    </MyButton>
                  )}
                  <MyButton
                    onClick={() => handleDeleteClick(manufacturer.id)}
                    color="red"
                  >
                    Удалить
                  </MyButton>
                  <Modal visible={modalActive} setVisible={setModalActive}>
                    {delId && (
                      <YON
                        setVisible={setModalActive}
                        del={deleteClick}
                        id={delId}
                        text="Подтвердите удаление кбренда, это приведет к удалению всех связанных товаров."
                      />
                    )}
                  </Modal>
                </>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Manufacturer;
