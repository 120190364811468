import { createSlice } from "@reduxjs/toolkit";
import {
  createWarehouse,
  deleteWarehouse,
  getWarehousesByUser,
  updateWarehouse,
} from "../../http/warehouseApi";

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWarehousesByUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWarehousesByUser.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(getWarehousesByUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createWarehouse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createWarehouse.fulfilled, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
      })
      .addCase(createWarehouse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteWarehouse.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteWarehouse.fulfilled, (state, { payload }) => {
        state.list = state.list.filter((item) => item.id !== payload.id);
        state.isLoading = false;
      })
      .addCase(deleteWarehouse.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(updateWarehouse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateWarehouse.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.list.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      })
      .addCase(updateWarehouse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const warehouseReducer = warehouseSlice.reducer;
