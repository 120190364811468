import { $authHost, $host } from "./index";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Загрузка всех производителей деталей
export const getAllManufacturer = createAsyncThunk(
  "@@manufacturer/loadAll",
  async (_, { rejectWithValue }) => {
    try {
      const res = await $host.get("api/manufacturer");
      return res.data;
    } catch (error) {
      return rejectWithValue(error.res.data);
    }
  }
);

// Создание нового производителя детали
export const createManufacturer = createAsyncThunk(
  "@@manufacturer/create",
  async (manufacturer, { rejectWithValue }) => {
    console.log("manufacturer", manufacturer);
    try {
      const res = await $authHost.post("api/manufacturer", manufacturer);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.res.data);
    }
  }
);

// Удаление производителя детали
export const deleteManufacturer = createAsyncThunk(
  "@@manufacturer/delete",
  async (id, { rejectWithValue }) => {
    try {
      const res = await $authHost.delete(`api/manufacturer/${id}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.res.data);
    }
  }
);

//Редактирование производителя детали
export const updateManufacturer = createAsyncThunk(
  "@@manufacturer/update",
  async ({ id, name, country, description }, { rejectWithValue }) => {
    try {
      const res = await $authHost.patch(`api/manufacturer/${id}`, {
        name,
        country,
        description,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.res.data);
    }
  }
);
