import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCategory } from "../../http/categoryApi";
import {
  deleteProduct,
  getFilterProductsFromCategoryAndType,
  updateProduct,
} from "../../http/productApi";
import ProductItem from "../productItem/ProductItem";
import { getTypesByCategoryWithProducts } from "../../http/typeApi";
import { clearList } from "../../features/slice/productSlice";

const EditProduct = () => {
  const dispatch = useDispatch();
  const allCategory = useSelector((state) => state.categoryList.list);
  const filterTypeList = useSelector((state) => state.typeList.filterList);
  const filterProduct = useSelector((state) => state.productList.list);
  const userId = useSelector((state) => state.user.user.id);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [activeState, setActiveState] = useState({ id: null, state: "" }); // Глобальное универсальное состояние

  const handleSetState = (id, state) => {
    setActiveState((prev) =>
      prev.id === id && prev.state === state
        ? { id: null, state: "" } // Сбрасываем, если повторно нажата кнопка
        : { id, state }
    );
  };

  const generateOptions = (types, parentID = null, level = 0) => {
    console.log(types[0].name, "работает");

    return types
      .filter((type) => type.parentID === parentID)
      .flatMap((type) => [
        <option key={type.id} value={type.id}>
          {`${"—".repeat(level)} ${type.name}`}
        </option>,
        ...generateOptions(types, type.id, level + 1),
      ]);
  };

  const handleProductCategory = (category) => {
    dispatch(clearList());
    setSelectedCategory(category.target.value);
    setSelectedType(null); // Сбрасываем тип при смене категории
  };

  const handleProductType = (type) => {
    setSelectedType(type.target.value);
  };

  const handleDeleteProduct = (productId) => {
    dispatch(deleteProduct(productId));
  };

  const handleSaveProduct = ({ updatedData, id }) => {
    dispatch(updateProduct({ updatedData, id }));
  };

  useEffect(() => {
    dispatch(loadCategory());
    //     eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allCategory && allCategory.length > 0) {
      setSelectedCategory(allCategory[0]?.id);
    }
  }, [allCategory]);

  useEffect(() => {
    if (selectedCategory) {
      dispatch(
        getTypesByCategoryWithProducts({ categoryId: selectedCategory, userId })
      );
    }
    //     eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  // Выполняем фильтрацию при изменении категории или типа
  useEffect(() => {
    dispatch(clearList()); // Очищаем store перед запросом
    if (selectedCategory && selectedType) {
      dispatch(
        getFilterProductsFromCategoryAndType({
          category: selectedCategory,
          typeId: selectedType,
          userId,
        })
      );
    } else if (selectedCategory && filterTypeList.length > 0) {
      // Если тип не выбран, фильтруем по первой доступной категории
      const defaultType = filterTypeList[0]?.id;
      setSelectedType(defaultType); // Устанавливаем первый доступный тип
      if (defaultType) {
        dispatch(
          getFilterProductsFromCategoryAndType({
            category: selectedCategory,
            typeId: defaultType,
            userId,
          })
        );
      }
    }
    //     eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, selectedType, filterTypeList, userId]);

  return (
    <div>
      <h1>Редактировать товар</h1>
      <label htmlFor="productCategorySelect">Выберите категорию:</label>
      <select id="productCategorySelect" onChange={handleProductCategory}>
        {allCategory && allCategory.length > 0 ? (
          generateOptions(allCategory)
        ) : (
          <option disabled>Нет доступных категорий</option>
        )}
      </select>
      <label htmlFor="productTypeSelect">Выберите тип:</label>
      <select
        id="productTypeSelect"
        onChange={handleProductType}
        value={selectedType || ""}
      >
        {filterTypeList && filterTypeList.length > 0 ? (
          generateOptions(filterTypeList)
        ) : (
          <option disabled>Нет доступных типов</option>
        )}
      </select>
      <h2>Список запчастей</h2>
      {Array.isArray(filterProduct) && filterProduct.length > 0 ? (
        filterProduct.map((product) => (
          <ProductItem
            key={product.id}
            product={product}
            onDelete={handleDeleteProduct}
            onSave={handleSaveProduct}
            activeState={activeState}
            setActiveState={handleSetState}
          />
        ))
      ) : (
        <p>Обновите страницу</p>
      )}
    </div>
  );
};

export default EditProduct;
