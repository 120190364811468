import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOneUser, updateUser } from "../../http/userApi";
import style from "./user.module.scss";
import MyButton from "../../UI/button/MyButton";
import Warehouse from "../../UI/warehouse/Warehouse";

const User = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.user.id);
  const userDetails = useSelector((state) => state.user.userDetails);
  console.log("userId", userId);
  console.log("userDetails", userDetails);

  const [isVisibleInput, setIsVisibleInput] = useState(false);
  const [formData, setFormData] = useState({
    address: "",
    brandName: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleEdit = () => {
    setFormData({ ...userDetails });
    setIsVisibleInput(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(updateUser({ userId, formData }));
    setIsVisibleInput(false);
  };

  useEffect(() => {
    if (userId) {
      dispatch(getOneUser(userId));
    }

    //     eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={style.container}>
      <h3>Данные пользователя</h3>
      {userDetails ? (
        <div>
          {" "}
          {!isVisibleInput ? (
            <>
              <div className={style.row}>
                <label>Имя: </label>
                <p className={style.p}> {userDetails.firstName}</p>
              </div>
              <div className={style.row}>
                <label>Фамилия: </label>
                <p className={style.p}> {userDetails.lastName}</p>
              </div>
              <div className={style.row}>
                <label>Бренд: </label>
                <p className={style.p}> {userDetails.brandName}</p>
              </div>
              <div className={style.row}>
                <label>почта: </label>
                <p className={style.p}> {userDetails.email}</p>
              </div>
              <div className={style.row}>
                <label>телефон: </label>
                <p className={style.p}> {userDetails.phone}</p>
              </div>
              <div className={style.row}>
                <label>адрес: </label>
                <p className={style.p}> {userDetails.address}</p>
              </div>
              <MyButton onClick={handleEdit}>Редактировать</MyButton>
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className={style.row}>
                <label>Имя: </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={style.row}>
                <label>Фамилия:</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={style.row}>
                <label>Бренд:</label>
                <input
                  type="text"
                  name="brandName"
                  value={formData.brandName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={style.row}>
                <label>почта:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={style.row}>
                <label>телефон:</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={style.row}>
                <label>адрес:</label>
                <textarea
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </div>
              <MyButton type="submit" color="green">
                Сохранить
              </MyButton>
              <MyButton
                onClick={() => {
                  setIsVisibleInput(false);
                }}
              >
                Отмена
              </MyButton>
            </form>
          )}
          <Warehouse />
        </div>
      ) : (
        <p>Данные пользователя не загружены</p>
      )}
    </div>
  );
};

export default User;
