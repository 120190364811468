import React, { useState } from "react";
import { useDispatch } from "react-redux";
import MyButton from "../../UI/button/MyButton";
import { createWarehouse } from "../../http/warehouseApi";

const CreateWarehouse = ({ userId }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ name: "", address: "" });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting: ", { ...formData, userId });
    dispatch(createWarehouse({ formData, userId }));
    setFormData({ name: "", address: "" });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Название:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>адрес:</label>
          <textarea
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <MyButton type="submit" color="green">
            Сохранить
          </MyButton>
          <MyButton>Отменить</MyButton>
        </div>
      </form>
    </div>
  );
};

export default CreateWarehouse;
