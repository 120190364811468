import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { create } from "../../http/productApi";
import stl from "./createPart.module.scss";
import MyButton from "../button/MyButton";
import { loadTypes } from "../../http/typeApi";
import { loadCategory } from "../../http/categoryApi";
import { getWarehousesByUser } from "../../http/warehouseApi";
import { getAllManufacturer } from "../../http/manufacturerApi";

const CreatePart = () => {
  const allCategories = useSelector((state) => state.categoryList.list);
  const allTypes = useSelector((state) => state.typeList.list);
  const userId = useSelector((state) => state.user.user.id);
  const warehouses = useSelector((state) => state.warehouseList.list);
  const manufacturer = useSelector((state) => state.manufacturerList.list);

  const dispatch = useDispatch();

  console.log("warehouses", warehouses);
  console.log("manufacturer", manufacturer);

  const generateOptions = (types, parentID = null, level = 0) => {
    return types
      .filter((type) => type.parentID === parentID)
      .flatMap((type) => [
        <option
          key={type.id}
          value={type.id}
          // disabled={types.some((t) => t.parentId === type.id)} // Делаем disabled, если у категории есть дочерние элементы
        >
          {`${"—".repeat(level)} ${type.name}`}
        </option>,
        ...generateOptions(types, type.id, level + 1),
      ]);
  };

  const {
    control,
    register,
    handleSubmit,
    setError,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dispatch(create(data));
      console.log("что передаем в дата", data);
    } catch (error) {
      setError("root", { message: "Произошла ошибка при создании детали" });
    }
  };

  useEffect(() => {
    dispatch(loadCategory());
    dispatch(loadTypes());
    dispatch(getWarehousesByUser(userId));
    dispatch(getAllManufacturer());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={stl.part}>
      <form className={stl.form} onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("name", {
            required: "Имя обязательно",
            minLength: {
              value: 3,
              message: "Имя должно быть не менее 3 символов",
            },
          })}
          type="text"
          placeholder="Название детали"
        />
        {errors.name && (
          <div className="text-red-500">{errors.name.message}</div>
        )}
        <input
          {...register("description", {
            required: "Описание обязательно",
            minLength: {
              value: 3,
              message: "Описание должно быть не менее 3 символов",
            },
          })}
          type="text"
          placeholder="Описание детали"
        />
        {errors.description && (
          <div className="text-red-500">{errors.description.message}</div>
        )}
        <input
          {...register("price", {
            required: "Цена обязательна",
            valueAsNumber: true,
          })}
          type="number"
          placeholder="Цена детали"
        />
        {errors.price && (
          <div className="text-red-500">{errors.price.message}</div>
        )}
        <input
          {...register("amount", {
            required: "Количество обязательно",
            valueAsNumber: true,
          })}
          type="number"
          placeholder="Количество деталей"
        />
        {errors.amount && (
          <div className="text-red-500">{errors.amount.message}</div>
        )}
        <select
          {...register("condition", { required: "Состояние обязательно" })}
        >
          <option disabled value="">
            Выбрать состояние
          </option>
          <option value="б/у">б/у</option>
          <option value="восстановленная">восстановленная</option>
          <option value="новая">новая</option>
        </select>
        {errors.condition && (
          <div className="text-red-500">{errors.condition.message}</div>
        )}
        <input
          {...register("internalCode", {
            required: "Внутренний код обязателен",
          })}
          type="text"
          placeholder="Внутренний код"
        />
        {errors.internalCode && (
          <div className="text-red-500">{errors.internalCode.message}</div>
        )}
        <input
          {...register("qrCode", {
            required: "QR-код обязателен",
          })}
          type="text"
          placeholder="QR-код"
        />
        {errors.qrCode && (
          <div className="text-red-500">{errors.qrCode.message}</div>
        )}
        <select
          {...register("manufacturerId", {
            required: "производитель обязателен",
          })}
        >
          <option value="">Выбрать производителя</option>
          {manufacturer?.map((manufacture) => (
            <option key={manufacture.id} value={manufacture.id}>
              {manufacture.name}
            </option>
          ))}
        </select>
        {errors.manufacturerId && (
          <div className="text-red-500">{errors.manufacturerId.message}</div>
        )}
        <input
          {...register("manufacturerPartNumber", {
            required: "Код производителя обязателен",
          })}
          type="text"
          placeholder="Код производителя"
        />
        {errors.manufacturerPartNumber && (
          <div className="text-red-500">
            {errors.manufacturerPartNumber.message}
          </div>
        )}
        {/* Категории с использованием Controller */}
        <div className={stl.checkboxGroup}>
          <span>Выберите категорию:</span>

          {/* Чекбокс "Выбрать все" */}
          <label>
            <input
              type="checkbox"
              checked={
                allCategories.length > 0 &&
                allCategories.every((category) =>
                  watch("categoryIds")?.includes(category.id)
                )
              }
              onChange={(e) => {
                const newValue = e.target.checked
                  ? allCategories.map((category) => category.id) // Добавить все бренды
                  : []; // Очистить
                setValue("categoryIds", newValue, { shouldValidate: true }); // Установить новое значение
              }}
            />
            Выбрать все
          </label>

          {/* Индивидуальные чекбоксы для брендов */}
          {allCategories.map((category) => (
            <Controller
              key={category.id}
              name="categoryIds"
              control={control}
              defaultValue={[]}
              rules={{ required: "Необходимо выбрать хотя бы один бренд" }} // Правило валидации
              render={({ field }) => (
                <label>
                  <input
                    type="checkbox"
                    checked={field.value.includes(category.id)} // Проверяем, выбран ли id
                    onChange={(e) => {
                      if (e.target.checked) {
                        // Добавляем category.id в массив
                        field.onChange([...field.value, category.id]);
                      } else {
                        // Удаляем category.id из массива
                        field.onChange(
                          field.value.filter((id) => id !== category.id)
                        );
                      }
                    }}
                  />
                  {category.name}
                </label>
              )}
            />
          ))}
          {errors.categoryIds && (
            <div className="text-red-500">{errors.categoryIds.message}</div>
          )}
        </div>{" "}
        <select {...register("typeId", { required: "Тип обязателен" })}>
          <option value="">Выбрать тип</option>
          {generateOptions(allTypes)}
        </select>
        {errors.typeId && (
          <div className="text-red-500">{errors.typeId.message}</div>
        )}
        <select {...register("warehouseId", { required: "склад обязателен" })}>
          <option value="">Выбрать склад</option>
          {warehouses?.map((warehouse) => (
            <option key={warehouse.id} value={warehouse.id}>
              {warehouse.name}
            </option>
          ))}
        </select>
        {errors.warehouseId && (
          <div className="text-red-500">{errors.warehouseId.message}</div>
        )}
        <div className={stl.inputContainer}>
          <input
            {...register("weight", {
              required: "Масса обязательна",
              validate: (value) => !isNaN(value) && parseFloat(value) >= 0,
            })}
            type="number"
            step="0.01"
            placeholder="Масса"
            className={stl.input} // Добавляем отступ, чтобы текст не перекрывалось
          />
          <span className={stl.inputSuffix}>кг.</span>
        </div>
        {errors.weight && (
          <div className="text-red-500">{errors.weight.message}</div>
        )}
        <div className={stl.inputContainer}>
          <input
            {...register("length", {
              required: "Длина обязательна",
              validate: (value) => !isNaN(value) && parseFloat(value) >= 0,
            })}
            type="number"
            step="0.001"
            placeholder="Длина"
            className={stl.input} // Добавляем отступ, чтобы текст не перекрывалось
          />
          <span className={stl.inputSuffix}>см.</span>
        </div>
        {errors.length && (
          <div className="text-red-500">{errors.length.message}</div>
        )}
        <div className={stl.inputContainer}>
          <input
            {...register("width", {
              required: "Ширина обязательна",
              valueAsNumber: true,
            })}
            type="number"
            step="0.01"
            placeholder="Ширина"
            className={stl.input} // Добавляем отступ, чтобы текст не перекрывалось
          />
          <span className={stl.inputSuffix}>см.</span>
        </div>
        {errors.width && (
          <div className="text-red-500">{errors.width.message}</div>
        )}
        <div className={stl.inputContainer}>
          <input
            {...register("height", {
              required: "Высота обязательна",
              validate: (value) => !isNaN(value) && parseFloat(value) >= 0,
            })}
            type="number"
            step="0.01"
            placeholder="Высота"
            className={stl.input} // Добавляем отступ, чтобы текст не перекрывалось
          />
          <span className={stl.inputSuffix}>см.</span>
        </div>
        {errors.height && (
          <div className="text-red-500">{errors.height.message}</div>
        )}
        <MyButton disabled={isSubmitting} type="submit">
          {isSubmitting ? "Создается..." : "Создать"}
        </MyButton>
        {errors.root && (
          <div className="text-red-500">{errors.root.message}</div>
        )}
      </form>
    </div>
  );
};

export default CreatePart;
