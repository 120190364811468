import { $authHost, $host } from "./index";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Создание нового типа детали
export const createCategory = createAsyncThunk(
  "@@category/create-category",
  async (category, { rejectWithValue }) => {
    try {
      const response = await $authHost.post("api/category", category);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Загрузка всех типов деталей
export const loadCategory = createAsyncThunk(
  "@@category/load-category",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $host.get("api/category");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "@@category/delete-category",
  async (id, { rejectWithValue }) => {
    try {
      const response = await $authHost.delete(`api/category/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "@@category/update-category",
  async (
    { id, name, description, parentID, order, imageURL },
    { rejectWithValue }
  ) => {
    try {
      const response = await $authHost.patch(`api/category/${id}`, {
        name,
        description,
        parentID,
        order,
        imageURL,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCategoryImage = createAsyncThunk(
  "@@category/update-image",
  async ({ categoryId, formData }, { rejectWithValue }) => {
    try {
      const response = await $authHost.patch(
        `api/category/img/${categoryId}`,
        formData // Передаём FormData напрямую
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Ошибка запроса");
    }
  }
);
