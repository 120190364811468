import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyButton from "../button/MyButton";
import {
  deleteWarehouse,
  getWarehousesByUser,
  updateWarehouse,
} from "../../http/warehouseApi";
import Modal from "../../components/modal/Modal";
import YON from "../../components/yon/Yon";
import CreateWarehouse from "../../features/createWarehouse/CreateWarehouse";

const Warehouse = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.user.id);
  const warehouses = useSelector((state) => state.warehouseList.list);
  console.log("warehouses", warehouses);

  const [formData, setFormData] = useState({ name: "", address: "" });
  const [isVisibleInput, setIsVisibleInput] = useState(false);
  const [editWarehouse, setEditWarehouse] = useState("");
  const [modalActive, setModalActive] = useState(false); // Управление модальным окном
  const [delId, setDelId] = useState(null); // ID для подтверждения удаления

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting: ", { ...formData, userId });
    dispatch(updateWarehouse({ id: userId, formData }));
    setIsVisibleInput(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEdit = (warehouse) => {
    setEditWarehouse(warehouse);
    setFormData({ name: warehouse.name, address: warehouse.location });
    setIsVisibleInput(true);
  };

  const handleDeleteClick = (id) => {
    setDelId(id);
    setModalActive(true); // Открыть модальное окно
  };
  const deleteClick = async (id) => {
    console.log("deleteClick", id);

    try {
      dispatch(deleteWarehouse(id));
      setDelId(null);
      setIsVisibleInput(false);
    } catch (error) {
      console.error("Ошибка при удалении бренда детали:", error);
    }
  };

  useEffect(() => {
    dispatch(getWarehousesByUser(userId));
    //     eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div>
      <h3>Ваши склады</h3>
      <hr />
      <label>Добавить склад</label>
      <CreateWarehouse userId={userId} />
      <hr />
      {warehouses &&
        warehouses?.map((warehouse) => (
          <div key={warehouse.id}>
            {editWarehouse.id === warehouse.id && isVisibleInput ? (
              <form onSubmit={handleSubmit}>
                <div>
                  <label>Название:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label>адрес:</label>
                  <textarea
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <MyButton type="submit" color="green">
                    Сохранить
                  </MyButton>
                  <MyButton
                    onClick={() => {
                      setIsVisibleInput(false);
                    }}
                  >
                    Отмена
                  </MyButton>
                  <MyButton
                    type="button"
                    onClick={() => handleDeleteClick(warehouse.id)}
                    color="red"
                  >
                    Удалить
                  </MyButton>
                  <Modal visible={modalActive} setVisible={setModalActive}>
                    {delId && (
                      <YON
                        setVisible={setModalActive}
                        del={deleteClick}
                        id={delId}
                        text="Подтвердите удаление кбренда, это приведет к удалению всех связанных товаров."
                      />
                    )}
                  </Modal>
                </div>
              </form>
            ) : (
              <>
                <h3> {warehouse.name}</h3>
                <h5>{warehouse.location}</h5>
                {!isVisibleInput && (
                  <MyButton onClick={() => handleEdit(warehouse)}>
                    Редактировать
                  </MyButton>
                )}
              </>
            )}
          </div>
        ))}
    </div>
  );
};

export default Warehouse;
