import React from "react";
import stl from "./admin.module.scss";
import MyButton from "../UI/button/MyButton";
import { Link, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const Admin = () => {
  const role = useSelector((state) => state.user.user.role);

  return (
    <div className={stl.wrapper}>
      <div>
        <Link to="/admin/category">
          <MyButton>Категории</MyButton>
        </Link>
        <Link to="/admin/types">
          <MyButton>Типы</MyButton>
        </Link>{" "}
        <Link to="/admin/manufacturer">
          <MyButton>Производители</MyButton>
        </Link>{" "}
        <Link to="/admin/product">
          {" "}
          <MyButton>Добавить товар</MyButton>
        </Link>
        <Link to="/admin/edit">
          {" "}
          <MyButton>Редактировать товар</MyButton>
        </Link>
        <Link to="/admin/user">
          {" "}
          <MyButton>Пользователь</MyButton>
        </Link>
        {/* {role === "ADMIN" && (
          <Link to="/admin/userAdmin">
            {" "}
            <MyButton>Пользователь</MyButton>
          </Link>
        )} */}
        <Outlet />
      </div>
    </div>
  );
};

export default Admin;
