import { createSlice } from "@reduxjs/toolkit";
import { getOneUser, updateUser } from "../../http/userApi";

// Начальное состояние
const initialState = {
  user: {}, // Авторизованный пользователь
  userDetails: {}, // Данные о пользователе по запросу
  loading: false,
  error: null,
};

// Создание слайса
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle load one user
      .addCase(getOneUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOneUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload.userOne; // Сохраняем загруженные данные о пользователе
      })
      .addCase(getOneUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }) // Handle updateType
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Экспортируем действия и редуктор
export const { setUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
