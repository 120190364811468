import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { userReducer } from "../features/slice/userSlice.js";
import axios from "axios";
import * as http from "../http/index.js";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { typeReducer } from "../features/slice/typeSlice.js";
import { productReducer } from "../features/slice/productSlice.js";
import { isAuthReducer } from "../features/slice/isAuth.js";
import { imageReducer } from "../features/slice/imageSlice.js";
import { categoryReducer } from "../features/slice/categorySlice.js";
import { searchReducer } from "../features/slice/searchSlice.js";
import { manufacturerReducer } from "../features/slice/manufacturerSlice.js";
import { warehouseReducer } from "../features/slice/warehouseSlice.js";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["isAuth", "user"],
  //   whitelist: ["isAuth", "city", "cart"],
};
const rootReducer = combineReducers({
  isAuth: isAuthReducer,
  user: userReducer,
  categoryList: categoryReducer,
  typeList: typeReducer,
  productList: productReducer,
  imageList: imageReducer,
  searchList: searchReducer,
  manufacturerList: manufacturerReducer,
  warehouseList: warehouseReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      thunk: {
        extraArgument: {
          client: axios,
          http,
        },
      },
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
