import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductItemShop from "./productItemShop/ProductItemShop";
import stl from "./shop.module.scss";
import { loadProduct } from "../http/productApi";

const Shop = () => {
  const dispatch = useDispatch();
  const { list, page, hasMore, loading } = useSelector(
    (state) => state.productList
  );

  // Загружаем первую порцию товаров ОДИН раз
  useEffect(() => {
    dispatch(loadProduct({ page: 1 }));
    //     eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Загружаем первую порцию товаров ОДИН раз
  useEffect(() => {
    if (page === 1 && list.length === 0) {
      dispatch(loadProduct({ page: 1 }));
    }
    //     eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, list.length]);

  // Функция обработки скролла
  const handleScroll = useCallback(() => {
    if (!hasMore || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      dispatch(loadProduct({ page }));
    }
    //     eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore, loading, page]);

  // Добавляем обработчик скролла
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <div className={stl.mapPage}>
      {list.length > 0 ? (
        list.map((item) => <ProductItemShop key={item.id} props={item} />)
      ) : (
        <p>Товары не загружены</p>
      )}
    </div>
  );
};

export default Shop;
