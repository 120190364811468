import { createSlice } from "@reduxjs/toolkit";
import {
  createManufacturer,
  deleteManufacturer,
  getAllManufacturer,
  updateManufacturer,
} from "../../http/manufacturerApi";

const manufacturerSlice = createSlice({
  name: "manufacturer",
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllManufacturer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllManufacturer.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(getAllManufacturer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createManufacturer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createManufacturer.fulfilled, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
      })
      .addCase(createManufacturer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteManufacturer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteManufacturer.fulfilled, (state, { payload }) => {
        state.list = state.list.filter((item) => item.id !== payload.id);
        state.isLoading = false;
      })
      .addCase(deleteManufacturer.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(updateManufacturer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateManufacturer.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.list.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      })
      .addCase(updateManufacturer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const manufacturerReducer = manufacturerSlice.reducer;
