import { $authHost } from "./index";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Загрузка всех складов по userId
export const getWarehousesByUser = createAsyncThunk(
  "@@warehouse/loadByUser",
  async (userId, { rejectWithValue }) => {
    try {
      const res = await $authHost.get(`api/warehouse/${userId}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Неизвестная ошибка");
    }
  }
);

// Создание нового производителя детали
export const createWarehouse = createAsyncThunk(
  "@@warehouse/create",
  async ({ formData, userId }, { rejectWithValue }) => {
    console.log("склад", { formData, userId });
    try {
      const response = await $authHost.post(
        "api/warehouse",
        { formData, userId },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return response.data;
    } catch (error) {
      // Обрабатываем ошибку и достаем данные из error.response
      return rejectWithValue(error.response?.data || "Неизвестная ошибка");
    }
  }
);

// Удаление склада пользователя
export const deleteWarehouse = createAsyncThunk(
  "@@warehouse/delete",
  async (id, { rejectWithValue }) => {
    try {
      const res = await $authHost.delete(`api/warehouse/${id}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.res.data);
    }
  }
);

//Редактирование производителя детали
export const updateWarehouse = createAsyncThunk(
  "@@warehouse/update",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const res = await $authHost.patch(
        `api/warehouse/${id}`,
        {
          formData,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.res.data);
    }
  }
);
