import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadOneProductURL } from "../../http/productApi";
import style from "./productItemShopId.module.scss";
import { Helmet } from "react-helmet-async";

const ProductItemShopId = () => {
  const { url } = useParams();
  console.log("ProductItemShopId url", url);

  const dispatch = useDispatch();

  const product = useSelector((state) => state.productList.selectedProduct);

  console.log("product", product);
  const [modalImage, setModalImage] = useState(null); // Для модального окна

  const handleImageClick = (image) => {
    setModalImage(image); // Устанавливаем изображение для просмотра
  };

  const closeModal = () => {
    setModalImage(null); // Закрываем модальное окно
  };

  useEffect(() => {
    // dispatch(loadProductId(id));
    dispatch(loadOneProductURL(url));
  }, []);
  return (
    <div className={style.productItemShopId}>
      {product && product.name ? (
        <Helmet>
          <title>{`${product.name}, ${product.manufacturerPartNumber} – Купить запчасти`}</title>
          <meta
            name="description"
            content={`Купить ${product.name}, ${product.manufacturerPartNumber} по выгодной цене. Авторазбор, доставка.`}
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>Запчасти – Купить запчасти</title>
          <meta
            name="description"
            content="Запчасти в наличии, авторазбор, доставка."
          />
        </Helmet>
      )}

      <div className={style.productItemShopId_row}>
        <div className={style.productItemShopId_content}>
          <h3>{product?.name}</h3>
          <div className={style.productItemShopId__imageGallery}>
            {product?.imageProducts.map((image, index) => (
              <img
                key={index}
                className={style.productItemShopId__image}
                src={`${process.env.REACT_APP_API_URL}/images/products/${image.card}`}
                alt={`${product.name}`}
                onClick={() =>
                  handleImageClick(
                    `${process.env.REACT_APP_API_URL}/images/products/${image.desktopFull}`
                  )
                }
              />
            ))}
          </div>
          <p>Количество: {product?.amount}шт.</p>
          <p>Состояние: {product?.condition}</p>
          <p>Описание: {product?.description}</p>
          <p>Код производителя: {product?.manufacturerPartNumber}</p>
          <p>Высота: {product?.height} см.</p>
          <p>Длина: {product?.length} см.</p>
          <p>Ширина: {product?.width} см.</p>
          <p>Масса: {product?.weight} кг.</p>

          {/* Модальное окно */}
        </div>
        <div className={style.productItemShopId_padding}>
          {" "}
          <p>Цена: {product?.price} р.</p>
          <p>Склад: {product?.warehouse["name"]}</p>
          <p>Адрес: {product?.warehouse["location"]}</p>
          <p>Продавец: {product?.warehouse["user"]["brandName"]}</p>
          <p>Телнфон продавца: {product?.warehouse["user"]["phone"]}</p>
        </div>
      </div>

      {modalImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={closeModal}
        >
          <img
            src={modalImage}
            alt="Просмотр изображения"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              borderRadius: "5px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProductItemShopId;
