import React, { useEffect, useState } from "react";
import MyButton from "../button/MyButton";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategory,
  deleteCategory,
  loadCategory,
  updateCategory,
} from "../../http/categoryApi";
import Modal from "../../components/modal/Modal";
import YON from "../../components/yon/Yon";
import ImageCropperCategoryAndType from "../imageCropperCategoryAndType/ImageCropperCategoryAndType";
import categorySTL from "./category.module.scss";
import { categoryImg, setcurrentImg } from "../../features/slice/categorySlice";

const Category = () => {
  const categories = useSelector((state) => state.categoryList.list);
  const currenImage = useSelector(categoryImg);
  const dispatch = useDispatch();
  // const timeoutRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    parentID: null,
    order: null,
    image: null,
  });
  const [editId, setEditId] = useState("");
  console.log("editId", editId);

  const [editVal, setEditVal] = useState("");
  const [isVisibleInput, setIsVisibleInput] = useState(false);
  const [modalActive, setModalActive] = useState(false); // Управление модальным окном
  const [activeState, setActiveState] = useState(false);
  const [delId, setDelId] = useState(null); // ID для подтверждения удаления
  const [isOpen, setIsOpen] = useState(false);
  const [expandedNodes, setExpandedNodes] = useState({});

  useEffect(() => {
    dispatch(loadCategory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddImg = () => {
    setActiveState(true); // Устанавливаем режим добавления изображения
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("name", formData.name);
    data.append("description", formData.description);
    if (formData.parentID) data.append("parentID", formData.parentID);
    data.append("order", formData.order);
    data.append("image", formData.image);

    try {
      await dispatch(createCategory(data));
      setFormData({
        name: "",
        description: "",
        parentID: null,
        order: null,
        image: null,
      });
    } catch (error) {
      console.error("Ошибка при создании типа детали:", error);
    }
  };

  const handleContainerBlur = (e) => {
    setTimeout(() => {
      const container = e.currentTarget; // Получаем контейнер
      if (!container) return; // Проверяем, что он не null

      if (!container.contains(document.activeElement)) {
        setEditId({});
        setIsVisibleInput(false);
      }
    }, 100);
  };

  // const handleContainerBlur = (e) => {
  //   // Проверяем, был ли клик вне области редактирования (по id элемента)
  //   if (!e.currentTarget.contains(e.relatedTarget)) {
  //     // Сбрасываем редактируемое состояние, если клик был вне
  //     setEditId({});
  //     setIsVisibleInput(false);
  //   }
  // };

  // Генерация списка с учётом вложенности
  const generateOptions = (categories, parentID = null, level = 0) => {
    return categories
      .filter((category) => category.parentID === parentID)
      .flatMap((category) => [
        <option key={category.id} value={category.id}>
          {`${"—".repeat(level)} ${category.name}`}
        </option>,
        ...generateOptions(categories, category.id, level + 1),
      ]);
  };

  const handleDeleteClick = (id) => {
    setDelId(id);
    setModalActive(true); // Открыть модальное окно
  };

  const deleteClick = async (id) => {
    try {
      dispatch(deleteCategory(id));
      setDelId(null);
    } catch (error) {
      console.error("Ошибка при удалении типа детали:", error);
    }
  };

  const handleSave = () => {
    dispatch(
      updateCategory({
        id: editId.id,
        name: editVal.name,
        parentID: editId.parentID,
      })
    );
    setIsVisibleInput(false);
  };

  const handleEdit = (elem) => {
    setEditId(elem);
    dispatch(setcurrentImg(elem.imageURL));
    setIsVisibleInput(true);
  };

  const toggleExpand = (id) => {
    setExpandedNodes((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const renderCategoriesTree = (categories, parentID = null) => {
    return categories
      .filter((category) => category.parentID === parentID)
      .map((category) => (
        <li key={category.id}>
          {/* <div onMouseDown={(e) => e.stopPropagation()} tabIndex={0}> */}
          <div
            onBlur={handleContainerBlur} // Добавляем обработчик на контейнер
            tabIndex={0} // Чтобы контейнер мог терять фокус
          >
            {categories.some((child) => child.parentID === category.id) && (
              <MyButton onClick={() => toggleExpand(category.id)}>
                {expandedNodes[category.id] ? "[-]" : "[+]"}
              </MyButton>
            )}
            {editId.id === category.id && isVisibleInput ? (
              <>
                <input
                  defaultValue={editId.name}
                  autoFocus
                  onChange={(e) =>
                    setEditVal((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
                <label>Родительский тип:</label>
                <select
                  name="parentID"
                  value={editId.parentID !== null ? editId.parentID : ""}
                  onChange={(e) => {
                    const selectedValue =
                      e.target.value === "" ? null : Number(e.target.value);
                    setEditId((prev) => ({
                      ...prev,
                      parentID: selectedValue,
                    }));
                  }}
                >
                  <option value="">Нет (Корневой элемент)</option>
                  {generateOptions(categories)}
                </select>
                {currenImage && (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/images/category/${currenImage}`}
                    alt={editId.name}
                    onClick={() => setIsOpen(true)} // Открываем модалку
                    className={categorySTL.categoryImage}
                  />
                )}

                {isOpen && (
                  <div
                    className={categorySTL.modal}
                    onClick={() => setIsOpen(false)}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}/images/category/${currenImage}`}
                      alt={editId.name}
                      className={categorySTL.fullscreenImage}
                    />
                  </div>
                )}
                {
                  <div onMouseDown={(e) => e.stopPropagation()}>
                    <ImageCropperCategoryAndType
                      categoryId={editId?.id}
                      setChange={() => setActiveState(null, "")}
                      onClick={() => {
                        setEditId({});
                        setIsVisibleInput(false);
                      }}
                    />
                  </div>
                }
                <MyButton onClick={() => handleSave()} color="green">
                  Сохранить
                </MyButton>
                <MyButton
                  onClick={() => {
                    setEditId({});
                    setIsVisibleInput(false);
                  }}
                  color="grey"
                >
                  Отмена
                </MyButton>
              </>
            ) : (
              <>{category.name}</>
            )}
            <>
              <MyButton
                onClick={() => handleDeleteClick(category.id)}
                color="red"
              >
                Удалить
              </MyButton>
              {/* Модальное окно */}
              <Modal visible={modalActive} setVisible={setModalActive}>
                {delId && (
                  <YON
                    setVisible={setModalActive}
                    del={deleteClick}
                    id={delId}
                    text="Подтвердите удаление категории, это приведет к удалению всех связанных товаров."
                  />
                )}
              </Modal>
            </>
            {!isVisibleInput && (
              <MyButton onClick={() => handleEdit(category)}>
                Редактировать
              </MyButton>
            )}
          </div>
          {expandedNodes[category.id] &&
            categories.some((child) => child.parentID === category.id) && (
              <ul>{renderCategoriesTree(categories, category.id)}</ul>
            )}
        </li>
      ));
  };

  return (
    <div className={{ display: "flex" }}>
      <h1>Типы товаров</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Название:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Описание:</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
        </div>
        <div>
          <label>Родительский тип:</label>
          <select
            name="parentID"
            value={formData.parentID || ""}
            onChange={handleChange}
          >
            <option value="">Нет (Корневой элемент)</option>
            {categories?.length > 0 && generateOptions(categories)}{" "}
          </select>
        </div>
        <div>
          <label>Порядок:</label>
          <input
            type="number"
            name="order"
            value={formData.order || ""}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Изображение:</label>
          <input type="file" name="image" onChange={handleFileChange} />
        </div>
        <MyButton type="submit">Создать</MyButton>
      </form>
      <div>
        <h1>Список типов деталей</h1>
        <ul>{categories?.length > 0 && renderCategoriesTree(categories)}</ul>
      </div>
    </div>
  );
};

export default Category;
